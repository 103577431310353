.navbar {
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.4);
}

.card {
    border: none;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
}

/* SidePanel.css */

.side-panel {
    width: 180px;
    min-width: 60px;
    height: 100vh;
    /* background-color: white; */
    position: fixed;
    top: 58px;
    left: 0;
    transition: width 0.3s ease-in-out;
    box-shadow: 2px 0 3px rgba(0, 0, 0, 0.2);
  }

  .side-panel.open {
    width: 180px; /* Set full width when open */
  }
  
  .side-panel:not(.open) {
    width: 60px; /* Set small width when closed */
  }
  
  .toggle-btn {
    margin-top: 20px;
    margin-left: 10px;
  }
  
  .panel-content {
    padding: 10px;
  }

  .container {
    transition: margin-left 0.3s ease-in-out;
  }
  
  .container.expanded {
    margin-left: 185px;
  }

  .container.closed {
    margin-left: 64px;
  }

  .sidepicons {
    font-size: 1.5rem;
    cursor: pointer;
  }

  .dashboardicons {
    font-size: 2rem;
  }

  .dashboard {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  /* HomePage.css */

.homepage {
    display: flex;
}

.side-panel-open .container.expanded {
  /* margin-left: 240px; */
}

.sticky-column {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  z-index: -1; /* Ensure it stays below other content */
}
.sticky-column + .sticky-column {
  left: 100px; /* Adjust this value according to your column width */
}
/* Add specific left positions for each sticky column */
.sticky-column:nth-child(2) {
  left: 155px;
}
.sticky-column:nth-child(3) {
  left: 200px;
}
.sticky-column:nth-child(4) {
  left: 250px;
}
.sticky-column:nth-child(5) {
  left: 300px;
}
.sticky-column:nth-child(6) {
  left: 350px;
}
